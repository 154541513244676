import PropTypes from "prop-types";
import loadingLamaGIF from "assets/static/loading/loading-lama.gif";

type LamaLoadingColors = "black" | "white";

const LamaLoading = (props) => {
  const { align, cover, color } = props;
  return (
    <div
      className={`loading text-${align} cover-${cover}`}
      style={color !== "black" ? { filter: "invert(100%)" } : {}}
    >
      <img width={70} src={loadingLamaGIF} alt="Loading..." />
    </div>
  );
};

LamaLoading.propTypes = {
  size: PropTypes.string,
  cover: PropTypes.string,
  color: PropTypes.oneOf<LamaLoadingColors>(["black", "white"]),
};

LamaLoading.defaultProps = {
  align: "center",
  cover: "inline",
  color: "black",
};

export default LamaLoading;
